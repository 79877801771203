import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import React, { Component } from "react"
import Header from "../components/navbar-blog.js"
import Mailchimp from "../components/mailchimp"
import Footer from "../components/footer"
import "../scss/blog.scss"

class PostTemplate extends Component {
    render() {
	const post = this.props.data.wordpressPost
	const metadescriptionraw = post.excerpt
	const metadescriptionformatted = metadescriptionraw.replace("#038;", "").replace("&#8217;", "'").replace("&#8211;", "-")
	const metadescription = metadescriptionformatted.replace(
	    /(<([^>]+)>)/gi, "")

	const metatitleraw = post.title + " - Next&Co"
	const metatitleformatted = metatitleraw.replace("#038;", "").replace("&#8217;", "'").replace("&#8211;", "-")
	const metatitle = metatitleformatted.replace(
	    /(<([^>]+)>)/gi, "")

	const metaurl = typeof window !== "undefined" ? window.location.href : ""

	return (
	    <>
		<Helmet>
		    <title>{metatitle}</title>
		    <meta name="description" content={metadescription} />
		    <meta name="title" content={metatitle} />
		    <meta property="og:type" content="website" />
		    <meta property="og:title" content={metatitle} />
		    <meta property="og:description" content={metadescription} />
		    <meta property="og:image" content="facebook.png" />
		    <meta property="twitter:url" content={metaurl} />
		    <meta property="twitter:title" content={metatitle} />
		    <meta property="twitter:description" content={metadescription} />
		    <meta property="twitter:image" content="twitter.png" />
		</Helmet>
		<Header />
		<section className="blog-header-image">
		    <img
			src={post.featured_media.localFile.childImageSharp.resolutions.src}
			alt={post.title}
		    />
		</section>
		<section className="blog-post">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container small-inner-container">
				{post.categories != null &&
				 post.categories.map(({ name }) => (
				     <h4
					 className="category"
					 dangerouslySetInnerHTML={{ __html: name }}
				     />
				 ))}
				<h1
				    className="blog-title"
				    dangerouslySetInnerHTML={{ __html: post.title }}
				/>

				<div className="blog-details">
				    <div className="row">
					<div className="author-image">
					    <svg
						width="27"
						height="12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					    >
						<path
						    d="M14.576 7.962a.175.175 0 00-.026.346.175.175 0 10.026-.346zM15.393 8.478a.201.201 0 100 .402.201.201 0 000-.402zM16.194 9a.23.23 0 10.224.223.224.224 0 00-.224-.224zM16.99 9.525a.258.258 0 10.252.253.252.252 0 00-.252-.253zM17.791 10.035a.28.28 0 100 .56.285.285 0 00.28-.28.28.28 0 00-.28-.28zM16.883 8.568a.23.23 0 10-.46 0 .23.23 0 00.46 0zM17.707 9.111a.252.252 0 00-.252-.252.258.258 0 00-.258.252.263.263 0 00.258.258.258.258 0 00.252-.258zM18.53 9.66a.28.28 0 10-.56 0 .286.286 0 00.28.28.28.28 0 00.28-.28zM17.97 8.685a.252.252 0 100-.505.252.252 0 000 .505zM18.771 9.256a.28.28 0 100-.56.28.28 0 000 .56z"
						    fill="#000"
						/>
						<path
						    d="M20.58.154c-1.327 0-3.07.852-5.326 2.577a36.52 36.52 0 00-2.079 1.714l-1.652 1.513c-1.9 1.68-4.482 3.546-5.748 3.557a3.518 3.518 0 01-.044-7.03c.346.016.686.098 1.002.24h.062a.308.308 0 00.302-.307.325.325 0 00-.078-.174l.134-.224a.258.258 0 00.146.033.302.302 0 00.168-.554l.095-.168a.303.303 0 00.493-.24.308.308 0 00-.179-.281A5.277 5.277 0 005.725.25a5.759 5.759 0 10.067 11.517c1.328 0 3.07-.852 5.327-2.577a39.03 39.03 0 002.079-1.714l1.652-1.518c1.9-1.68 4.481-3.546 5.748-3.552a3.512 3.512 0 11.044 7.025 2.722 2.722 0 01-.991-.235h-.034a.308.308 0 00-.302.308c0 .067.024.132.067.185l-.135.23a.313.313 0 00-.145-.04.308.308 0 00-.266.45.308.308 0 00.098.11l-.101.168a.27.27 0 00-.185-.067.304.304 0 00-.254.472.297.297 0 00.13.11c.671.335 1.404.526 2.152.56A5.764 5.764 0 1020.58.155z"
						    fill="#000"
						/>
						<path
						    d="M11.781 3.937a.174.174 0 100-.35.174.174 0 000 .35zM10.98 3.42a.201.201 0 100-.403.201.201 0 000 .402zM10.178 2.899a.23.23 0 100-.46.23.23 0 000 .46zM9.383 2.378a.252.252 0 100-.504.252.252 0 000 .504zM8.582 1.862a.28.28 0 100-.56.28.28 0 000 .56zM9.49 3.33a.23.23 0 10.459 0 .23.23 0 00-.46 0zM8.666 2.804a.258.258 0 00.252.258.263.263 0 00.258-.258.258.258 0 00-.258-.252.252.252 0 00-.252.252zM7.854 2.243a.28.28 0 10.56 0 .286.286 0 00-.28-.28.28.28 0 00-.28.28zM8.414 3.212a.252.252 0 100 .505.252.252 0 000-.505zM7.601 2.64a.28.28 0 00-.28.28.286.286 0 00.28.28.28.28 0 100-.56z"
						    fill="#000"
						/>
					    </svg>
					</div>
					<div className="column">
					    <p>Next&Co</p>
					    <p>
						{post.date} // {post.acf.read_time}
					    </p>
					</div>
				    </div>
				</div>

				<div
				    className="blog-post-content"
				    dangerouslySetInnerHTML={{ __html: post.content }}
				/>

				<div className="blog-content-lower">
				    <p className="blog-content-lower-link">
					TheNext is a publication of{" "}
					<Link className="" to="/">
					    Next&Co
					</Link>
				    </p>
				    <div className="row">
					{post.tags != null &&
					 post.tags.map(({ name }) => (
					     <h4
						 className="tag"
						 dangerouslySetInnerHTML={{ __html: name }}
					     />
					 ))}
				    </div>
				    <div className="column"></div>
				</div>
			    </div>
			</div>
		    </div>
		</section>
		<section className="mailchimp-signup-post">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<Mailchimp />
			    </div>
			</div>
		    </div>
		</section>
		<Footer />
	    </>
	)
    }
}

PostTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "DD, MMM")
      categories {
        id
        name
        slug
      }
      acf {
        read_time
      }
      slug
      wordpress_id
      featured_media {
        localFile {
          childImageSharp {
            resolutions(width: 2880, height: 1200) {
              src
              width
              height
            }
          }
        }
      }
      categories {
        name
      }
      tags {
        name
      }
    }
  }
`
